import { createRouter, createWebHistory } from 'vue-router'

const options = [
  { path: '/', component: 'HomePage', name: 'Home', props: false },
  { path: '/:pathMatch(.*)*', component: 'NotFound', name: 'Página Não Encontrada', props: false },
  { path: '/login', component: 'UserLogin', name: 'Login', props: false },
  { path: '/changes', component: 'ChangesLog', name: 'Histórico de Mudanças', props: false }
]

const routes = options.map(route => {
  return {
    path: route.path,
    component: () => import(`@/views/${route.component}.vue`),
    name: route.name,
    props: route.props
  }
})

const router = createRouter({
  history: createWebHistory(),
  routes
})

// Redirect to login if not authenticated
router.beforeEach((to, from, next) => {
  const user = JSON.parse(localStorage.getItem('user'))

  if (to.name !== 'Login' && (!user || !user.token)) {
    next({ name: 'Login', query: { redirect: to.fullPath } })
  } else {
    next()
  }
})

const matomoConfig = {
  host: 'https://hit.embrapa.io',
  siteId: import.meta.env.VITE_MATOMO_ID,
  router,
  preInitActions: [
    ['setCustomDimension', 1, import.meta.env.VITE_STAGE],
    ['setCustomDimension', 2, import.meta.env.VITE_VERSION]
  ]
}

export { router, matomoConfig }
