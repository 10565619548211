<template>
  <v-snackbar
    v-model="showSnack"
  >
    {{ snackText }}
    <template v-slot:actions>
      <v-btn
        variant="text"
        @click="showSnack = false"
      >
        Fechar
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script setup>
import { useSnackbarStore } from '@/stores/snackbarStore'
import { storeToRefs } from 'pinia'

const snackbarStore = useSnackbarStore()
const { snackText, showSnack } = storeToRefs(snackbarStore)

</script>
