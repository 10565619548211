<template>
  <v-snackbar v-model="hasUpdate" :multi-line="true" :timeout="60000">
    Nova versão disponível!
    <v-btn
      variant="text"
      @click="update()"
      color="info"
    >
      Atualizar
    </v-btn>
    <template v-slot:actions>
      <v-btn icon="mdi-close" @click="hasUpdate = false"></v-btn>
    </template>
  </v-snackbar>
</template>

<script setup>
import { ref, onMounted } from 'vue'

const hasUpdate = ref(false)

const update = () => {
  window.location.reload()
}

onMounted(() => {
  document.addEventListener(
    'hasUpdate',
    () => {
      hasUpdate.value = true
    },
    false
  )
})
</script>
