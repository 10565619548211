<template>
  <v-dialog
    v-model="activityDescriptionModel"
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        {{ activity.name }}
      </v-card-title>
      <v-card-text>
        <b>Descrição:</b>
        <div v-html="formattedDescription"></div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
        color="error"
        variant="text"
        @click="activityDescriptionModel = false"
        prepend-icon="mdi-close"
        >
          Fechar
        </v-btn>
    </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, watch, computed } from 'vue'

const activityDescriptionModel = defineModel()
const props = defineProps(['currentUser', 'activity'])

const activity = ref(props.activity)
const currentUser = ref(props.currentUser)

watch(props, () => {
  currentUser.value = props.currentUser
  activity.value = props.activity
})

const formattedDescription = computed(() => {
  // Replace newline characters with <br> for HTML display
  return activity.value.description.replace(/\n/g, '<br>');
})

</script>
