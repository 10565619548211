<template>
  <v-list style="background-color: transparent;">
    <v-btn
      variant="text"
      color="white"
      to="/login"
      block
      v-if="!user || !user.authenticated"
      prepend-icon="mdi-account"
    >
      Login
    </v-btn>
    <v-list-item class="custom-list-item">
    <div class="avatar-text-container">
      <v-avatar>
        <v-img :src="userPicture" :alt="user.name" />
      </v-avatar>
      <div class="text-container">
        <v-list-item-title v-html="user.name.trim() !== '' ? user.name : user.email" />
        <v-list-item-subtitle v-if="user.name.trim() !== ''">
          {{ user.email }}
        </v-list-item-subtitle>
      </div>
      <v-menu activator="parent" location="bottom">
        <template v-slot:activator="{ props }">
          <v-btn icon="mdi-dots-vertical" v-bind="props" class="menu-btn"></v-btn>
        </template>
        <v-list>
          <v-list-item @click="profile = true">
            <v-icon>mdi-clipboard-account</v-icon>
            Dados Pessoais
          </v-list-item>
          <v-list-item @click="logoff()">
            <v-icon>mdi-power</v-icon>
            Sair
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-list-item>
    <v-dialog v-model="profile" width="540px" persistent :fullscreen="$vuetify.display.xsOnly">
      <v-card>
        <v-card-title>
          <v-spacer />
          <v-btn icon="mdi-close" @click="cancel()"></v-btn>
        </v-card-title>
        <div class="mx-auto text-center">
          <v-avatar size="128" class="mt-0 mb-2">
            <v-img :src="user.picture !== '' ? user.picture : require('@/assets/user.png')" />
          </v-avatar>
        </div>
        <v-card-text class="text-center">
          Para definir ou alterar sua imagem de perfil, utilize o serviço <a href="https://gravatar.com" target="_blank">Gravatar</a>.<br>
          <strong>Atenção!</strong> A Embrapa e seus parceiros <u>NÃO</u> armazenam sua foto.
        </v-card-text>
        <v-alert v-if="error" type="error" icon="mdi-alert" :value="error" transition="scale-transition" class="mx-2 my-0">
          {{ erroMessage }}
        </v-alert>
        <v-card-text class="text-center">
          <v-text-field
            label="e-Mail"
            v-model="user.email"
            variant="outlined"
            append-icon="mdi-email"
            disabled
          />

          <v-text-field
            label="Nome (opcional)"
            v-model="name"
            variant="outlined"
            append-icon="mdi-pencil"
            hint="Seu nome, apelido ou como quer ser chamado."
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            variant="text"
            @click="remove = !remove"
          >
            Apagar Perfil
            <v-icon color="error">
              {{ remove ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
            </v-icon>
          </v-btn>

          <v-spacer />

          <v-btn
            color="success white--text"
            variant="flat"
            large
            @click="save()"
            :loading="loading"
          >
            Salvar
            <v-icon class="ml-1">
              mdi-check
            </v-icon>
          </v-btn>
        </v-card-actions>
        <v-expand-transition>
          <div v-show="remove">
            <v-divider />
            <v-card-text class="mb-0 pb-0 text-justify">
              <p>
                Você pode <strong>apagar completamente seu perfil de usuário</strong> a qualquer momento.
                Isto removerá todas as suas informações dos nossos servidores remotos, tal como seus dados pessoais, de fazendas e os utilizados nas simulações.
                Esteja ciente, no entanto, que esta é uma <u>ação irreversível</u>, ou seja, seus dados e histórico de acesso serão <u>permanentemente apagados</u>
                sem qualquer possibilidade de recuperação.
              </p>
            </v-card-text>
            <v-alert v-if="error" type="error" icon="mdi-alert" :value="error" transition="scale-transition" class="mx-2 my-0">
              {{ erroMessage }}
            </v-alert>
            <v-window v-model="step">
              <v-window-item>
                <v-card-actions>
                  <v-switch
                    color="primary"
                    v-model="aware"
                    label="Estou ciente e quero continuar."
                    inset
                    class="ml-2"
                  />

                  <v-spacer />

                  <v-btn
                    color="primary"
                    text
                    large
                    @click="sendRemovePin()"
                    :disabled="!aware"
                    :loading="loading"
                    append-icon="mdi-arrow-right"
                  >
                    Prosseguir
                  </v-btn>
                </v-card-actions>
              </v-window-item>
              <v-window-item>
                <v-card-text class="text-justify red darken-2 white--text">
                  Um número de 6 dígitos foi enviado para o e-mail <strong>{{ user.email }}</strong>. Se tiver realmente certeza de que quer continuar, insira-o abaixo:
                </v-card-text>
                <div class="input-wrapper my-5" style="width: 280px; margin: 0 auto;">
                  <v-otp-input v-model="pin" :length="6" />
                </div>
                <v-card-actions>
                  <v-btn
                    color="error"
                    variant="text"
                    @click="cancel()"
                  >
                    Cancelar
                  </v-btn>

                  <v-spacer />

                  <v-btn
                    color="warning"
                    variant="flat"
                    :disabled="pin.length !== 6"
                    large
                    @click="removeAccount()"
                    :loading="loading"
                    append-icon="mdi-check"
                  >
                    Remover Conta
                  </v-btn>
                </v-card-actions>
              </v-window-item>
            </v-window>
          </div>
        </v-expand-transition>
      </v-card>
    </v-dialog>
    <message-wrapper v-model="snackbar" :text="snackbarText" />
    <confirm-wrapper v-model="removeDialog" :newTitle="removeTitle" :newMessage="removeMessage" :newOptions="removeOptions" @consent="reload" />
    <confirm-wrapper v-model="logoffDialog" :newTitle="logoffTitle" :newMessage="logoffMessage" :newOptions="logoffOptions" @consent="reload" />
  </v-list>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import axios from 'axios'
import { errorMessage } from '@/helpers/error.js'
import MessageWrapper from '@/components/MessageSnack.vue'
import ConfirmWrapper from '@/components/ConfirmDialog.vue'

const user = ref({
  name: '',
  email: '',
  picture: '',
  token: ''
})

const userPicture = computed(() => {
  return user.value.picture !== ''
    ? user.value.picture
    : new URL('@/assets/user.png', import.meta.url).href
})

const name = ref('')
const profile = ref(false)
const remove = ref(false)

const logoffDialog = ref(false)
const logoffTitle = ref('Sair')
const logoffMessage = ref('Deseja realmente sair/deslogar do aplicativo? Atenção! Todos os dados NÃO SINCRONIZADOS serão PERMANENTE PERDIDOS.')
const logoffOptions = ref({ color: 'error' })

const removeDialog = ref(false)
const removeTitle = ref('Limpar Dados Locais')
const removeMessage = ref('Os seus dados na nuvem foram completamente apagados, bem como suas informações de login neste dispositivo. Deseja apagar também os dados locais? Você não pode continuar utilizando o aplicativo sem se identificar.')
const removeOptions = ref({ color: 'warning' })

const aware = ref(false)
const step = ref(0)
const pin = ref('')
const loading = ref(false)
const error = ref(false)
const erroMessage = ref('')
const snackbarText = ref('')
const snackbar = ref(false)

const api = import.meta.env.VITE_API

const handleError = (err) => {
  loading.value = false
  erroMessage.value = errorMessage(err)
  error.value = true
}

const checkOnline = () => {
  if (!navigator.onLine) {
    erroMessage.value = 'É necessário uma conexão com a internet para prosseguir! Por favor, verifique suas configurações de rede ou tente novamente mais tarde.'
    error.value = true
    return false
  }
  return true
}

onMounted(() => {
  const storedUser = JSON.parse(localStorage.getItem('user'))
  if (storedUser.authenticated) {
    user.value = storedUser
  }
})

const cancel = () => {
  name.value = ''
  remove.value = false
  aware.value = false
  step.value = 0
  pin.value = ''
  loading.value = false
  profile.value = false
}

const sendRemovePin = () => {
  if (!checkOnline()) return

  loading.value = true

  axios.get(api + '/status', { timeout: 2000 }).then(() => {
    axios.post(api + '/user/remove/pin', { email: user.value.email }, { headers: { Authorization: `Bearer ${user.value.token}` } })
      .then(() => {
        loading.value = false
        step.value++
      })
      .catch(err => handleError(err))
  }).catch(err => handleError(err))
}

const removeAccount = () => {
  if (!checkOnline()) return

  loading.value = true

  axios.get(api + '/status', { timeout: 2000 }).then(() => {
    axios.post(api + '/user/remove/confirm', { pin: pin.value }, { headers: { Authorization: `Bearer ${user.value.token}` } })
      .then(() => {
        snackbarText.value = 'Todos os seus dados na nuvem foram completamente apagados!'
        snackbar.value = true
        removeDialog.value = true
        cancel()
      })
      .catch(err => handleError(err))
  }).catch(err => handleError(err))
}

const save = () => {
  if (!checkOnline()) return

  loading.value = true

  axios.get(api + '/status', { timeout: 2000 }).then(() => {
    axios.post(api + '/user/name', { name: name.value }, { headers: { Authorization: `Bearer ${user.value.token}` } })
      .then(() => {
        user.value.name = name.value
        localStorage.setItem('user', JSON.stringify(user.value))
        snackbarText.value = 'Seu nome foi alterado com sucesso!'
        snackbar.value = true
        loading.value = false
        profile.value = false
      })
      .catch(err => handleError(err))
  }).catch(err => handleError(err))
}

const cleanup = () => {
  user.value = {
    authenticated: false,
    name: '',
    email: '',
    picture: '',
    token: ''
  }
  localStorage.setItem('user', JSON.stringify(user.value))
  localStorage.setItem('reliable', false)
  localStorage.setItem('email', '')
}

const logoff = () => {
  logoffDialog.value = true
}

const reload = () => {
  cleanup()
  window.location.reload()
}

</script>

<style scoped>
.custom-list-item {
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.avatar-text-container {
  display: flex;
  align-items: center;
}

.text-container {
  margin-left: 16px;
  display: flex;
  flex-direction: column;
}

.menu-btn {
  margin-left: 16px;
}

</style>
