<template>
  <v-btn-toggle v-model="filter" mandatory class="mx-5 pa-1">
    <v-btn value="all" icon="mdi-check-all"></v-btn>
    <v-btn value="admin" icon="mdi-shield-crown-outline"></v-btn>
    <v-btn value="vegetal" icon="mdi-sprout"></v-btn>
    <v-btn value="animal" icon="mdi-cow"></v-btn>
  </v-btn-toggle>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue'

const emit = defineEmits(['filter'])

const filter = ref('')

onMounted(() => {
  filter.value = 'all'
})

watch(filter, () => {
  emit('filter', filter.value)
})
</script>
