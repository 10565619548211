<template>
  <div class="bg-white" v-if="openUsersList">
    <v-toolbar color="teal">
        <v-toolbar-title>Usuários</v-toolbar-title>
        <v-spacer></v-spacer>

        <!-- SAVE CHANGES -->
        <!-- <v-fab
          :active="changes.length !== 0"
          extended
          color="success"
          prepend-icon="mdi-content-save-outline"
          text="Salvar"
          @click="saveChanges()"
        ></v-fab> -->

        <!-- SEARCH BAR -->
        <SearchForm @search="handleSearch"/>

        <!-- RADIO BUTTONS -->
        <FilterRadios @filter="handleFilter"/>

        <v-btn
          v-if="currentUser.admin"
          @click="newUserModel = true"
          prepend-icon="mdi-plus"
        >
          Novo Usuário
        </v-btn>
      </v-toolbar>
    <v-table class="table-container">
      <template v-slot:default>
        <thead class="table-header">
        <tr>
          <th class="px-4 py-3">
            Usuário
            <v-icon size="small" v-if="sortUserOrder" @click="userStore.sortUser('asc'); sortUserOrder = !sortUserOrder">
              mdi-arrow-up
            </v-icon>
            <v-icon size="small" v-if="!sortUserOrder" @click="userStore.sortUser('dsc'); sortUserOrder = !sortUserOrder">
              mdi-arrow-down
            </v-icon>
          </th>
          <th class="px-4 py-3">
            <v-icon class="mr-1">mdi-shield-crown-outline</v-icon>
            Administrador
          </th>
          <th class="px-4 py-3">
            <v-icon class="mr-1">mdi-sprout</v-icon>
            Vegetal
          </th>
          <th class="px-4 py-3">
            <v-icon class="mr-1">mdi-cow</v-icon>
            Animal
          </th>
          <th v-if="currentUser.admin" class="px-4 py-3 text-center">Opções</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in userStore.filteredUsers" :key="user._id" class="border-b">
          <td class="px-4 py-3 font-medium text-grey-900">
            <span v-if="user.name !== ''">
              {{ user.name }}
              <v-icon color="green">
                {{ user.email === currentUser.email ? 'mdi-account-circle' : '' }}
              </v-icon>
              <br>
              <span class="text-grey-500">{{ user.email }}</span>
            </span>
            <span v-else>
              {{ user.email }}
              <v-icon color="green">
                {{ user.email === currentUser.email ? 'mdi-account-circle' : '' }}
              </v-icon>
            </span>
          </td>
          <td class="px-4 py-3">
            <v-switch
              v-model="user.admin"
              color="teal"
              :disabled="!currentUser.admin"
              @update:modelValue="userStore.storeChanges(user._id, 'admin', $event)"
            ></v-switch>
          </td>
          <td class="px-4 py-3">
            <v-switch
              v-model="user.vegetal"
              color="teal"
              :disabled="!currentUser.admin || user.admin"
              @update:modelValue="userStore.storeChanges(user._id, 'vegetal', $event)"
            ></v-switch>
          </td>
          <td class="px-4 py-3">
            <v-switch
              v-model="user.animal"
              color="teal"
              :disabled="!currentUser.admin || user.admin"
              @update:modelValue="userStore.storeChanges(user._id, 'animal', $event)"
            ></v-switch>
          </td>
          <td class="px-4 py-3 text-center">
            <v-btn-group variant="outlined" divided>
              <v-btn
                v-if="currentUser.admin"
                icon="mdi-trash-can-outline"
                @click="openRemoveUserModel(user._id)"
              ></v-btn>
              <v-btn
                :disabled="!changes.find(usr => usr._id === user._id)"
                icon="mdi-content-save-outline"
                @click="userStore.saveChanges(user._id)"
              ></v-btn>
            </v-btn-group>
          </td>
        </tr>
      </tbody>
      </template>
    </v-table>

    <!-- CREATE NEW USER -->
    <CreateUserDialog v-model="newUserModel" />

    <!-- REMOVE NEW USER -->
    <RemoveUserDialog v-model="removeUserModel" :userId="removeUserId" />

    <MessageSnack v-model="snackbar" :text="snackbarText" />
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import CreateUserDialog from '@/components/CreateUserDialog.vue'
import RemoveUserDialog from '@/components/RemoveUserDialog.vue'
import SearchForm from '@/components/SearchForm.vue'
import FilterRadios from '@/components/FilterRadios.vue'
import MessageSnack from './MessageSnack.vue'
import { useUserStore } from '@/stores/userStore'
import { useChangesStore } from '@/stores/changesStore.js'
import { storeToRefs } from 'pinia'

const userStore = useUserStore()
const { filter, search, currentUser } = storeToRefs(userStore)

const changesStore = useChangesStore()
const { changes } = storeToRefs(changesStore)

const openUsersList = defineModel()

const newUserModel = ref(false)
const removeUserModel = ref(false)
const removeUserId = ref(null)
const snackbar = ref(false)
const snackbarText = ref('')
const sortUserOrder = ref(false)

onMounted(async () => {
  userStore.fetchUsers()
  userStore.sortUser('asc')
})

const handleSearch = (searchValue) => {
  search.value = searchValue
}

const handleFilter = (filterValue) => {
  filter.value = filterValue
}

const openRemoveUserModel = (userId) => {
  for (let i = 0; i < changes.value.length; i++) {
    if (changes.value[i].id === userId) {
      changes.value.splice(i, 1)
    }
  }
  removeUserId.value = userId
  removeUserModel.value = true
}

</script>

<style scoped>
.currentUser {
  border-left: 4px solid lightgreen;
}

.bg-white {
  background-color: #ffffff;
  position: relative;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
}

.table-container {
  width: 100%;
}

.table-header {
  font-size: 0.9rem;
  color: #4b5563;
  text-transform: uppercase;
  background-color: #f3f4f6;
}

.action-link {
  color: #4f46e5;
  text-decoration: none;
}

.action-link:hover {
  text-decoration: underline;
}
</style>
