import { defineStore, storeToRefs } from 'pinia'
import axios from 'axios'
import { useChangesStore } from './changesStore'
import { useSnackbarStore } from './snackbarStore'

const api = import.meta.env.VITE_API

export const useUserStore = defineStore('user', {
  state: () => ({
    users: [],
    currentUser: {},
    filter: "",
    search: ""
  }),

  actions: {
    getUser() {
      const storedUser = JSON.parse(localStorage.getItem('user'));

      if (storedUser?.authenticated) {
        this.currentUser = storedUser;
      }
    },
    async fetchUsers() {
      try {
        // Check API status first
        await axios.get(api + '/status', { timeout: 2000 })

        // Fetch users from the API with authentication
        const usersResponse = await axios.get(api + '/manager/user/list', {
          headers: { Authorization: 'Bearer ' + this.currentUser.token }
        })

        this.users = usersResponse.data
      } catch (error) {
        console.error('Error fetching users:', error)
      }
    },

    async saveUser(user) {
      const snackbarStore = useSnackbarStore()
      const changesStore = useChangesStore()
      const { changes } = storeToRefs(changesStore)

      try {
        // Fetch the latest user data by ID
        const fetchedUser = await this.getUserById(user._id)

        const updatedUser = {
          _id: user._id,
          name: user.name || fetchedUser.name,
          email: user.email || fetchedUser.email,
          admin: user.admin === null ? fetchedUser.admin : user.admin,
          vegetal: user.vegetal === null ? fetchedUser.vegetal : user.vegetal,
          animal: user.animal === null ? fetchedUser.animal : user.animal
        }

        // Check API status before attempting the update
        await axios.get(api + '/status', { timeout: 2000 })

        // Update the user
        await axios.post(api + '/manager/user', {
          adminEmail: this.currentUser.email,
          user: updatedUser
        }, {
          headers: { Authorization: 'Bearer ' + this.currentUser.token }
        })

        // Refresh the user list after updating
        if (changes.value.length === 0) {
          await this.fetchUsers()
        }

        // Record the revision
        await axios.post(api + '/manager/revision', {
          userEmail: this.currentUser.email
        }, {
          headers: { Authorization: 'Bearer ' + this.currentUser.token }
        })
        
      } catch (error) {
        snackbarStore.openSnackbar('Não foi possível salvar as alterações')
        console.error('Error saving user:', error)
      }
    },

    async getUserById(id) {
      try {
        // Check API status
        await axios.get(api + '/status', { timeout: 2000 })

        // Fetch user by ID
        const response = await axios.get(api + '/user/' + id, {
          headers: { Authorization: 'Bearer ' + this.currentUser.token }
        })

        return response.data[0]
      } catch (error) {
        console.error('Error fetching user by ID:', error)
        return null
      }
    },
    storeChanges(userId, field, fieldValue) {
      const changesStore = useChangesStore()
      const { changes } = storeToRefs(changesStore)

      let newUserChanges = null
    
      switch (field) {
        case 'admin':
          newUserChanges = {
            _id: userId,
            admin: fieldValue,
            vegetal: fieldValue ? true : null,
            animal: fieldValue ? true : null
          }
          break
        case 'vegetal':
          newUserChanges = {
            _id: userId,
            admin: null,
            vegetal: fieldValue,
            animal: null
          }
          break
        case 'animal':
          newUserChanges = {
            _id: userId,
            admin: null,
            vegetal: null,
            animal: fieldValue
          }
          break
      }
    
      for (let i = changes.value.length - 1; i >= 0; i--) {
        if (changes.value[i]._id === userId) {
          if ((field === 'admin' && changes.value[i].admin !== null && changes.value[i].admin === !fieldValue) ||
          (field === 'vegetal' && changes.value[i].vegetal !== null && changes.value[i].vegetal === !fieldValue) ||
          (field === 'animal' && changes.value[i].animal !== null && changes.value[i].animal === !fieldValue)) {
            changes.value.splice(i, 1)
            return
          }
        }
      }
    
      changes.value.push(newUserChanges)
    },
    async saveChanges(userId) {
      const changesStore = useChangesStore()
      const { changes } = storeToRefs(changesStore)

      for (let i = changes.value.length - 1; i >= 0; i--) {
        const user = changes.value[i]
        
        if (user._id === userId) {
          try {
            await this.saveUser(user)
            // Remove the saved change from the changes array
            changes.value.splice(i, 1)
          } catch (error) {
            console.error(`Error saving user ${user._id}:`, error)
          }
        }
      }
    },
    sortUser(sortOrder) {
  
      if (!Array.isArray(this.filteredUsers)) {
        console.log(this.filteredUsers)
        console.error('filteredUsers is not an array.')
        return
      }
    
      this.filteredUsers.sort((a, b) => {
        let aValue = ''
        let bValue = ''
    
        aValue = a && a.email !== undefined && a.email !== null ? a.email.toString() : ''
        bValue = b && b.email !== undefined && b.email !== null ? b.email.toString() : ''
    
        // Compare values based on the specified order
        if (sortOrder === 'asc') {
          return aValue.localeCompare(bValue)
        } else {
          return bValue.localeCompare(aValue)
        }
      })
    
      // console.log('Sorting by: email', sortOrder)
      // this.filteredUsers.forEach(user => {
      //   if (user.email) {
      //     console.log(user.email)
      //   } else {
      //     console.warn('Missing email for user:', user)
      //   }
      // })
    }
  },

  getters: {
    filteredUsers(state) {
      let auxUsers = state.users
    
      switch (this.filter) {
        case 'admin':
          auxUsers = auxUsers.filter(user => user.admin)
          break
        case 'vegetal':
          auxUsers = auxUsers.filter(user => user.vegetal)
          break
        case 'animal':
          auxUsers = auxUsers.filter(user => user.animal)
          break
      }
    
      if (state.search !== '') {
        auxUsers = auxUsers
          .filter(user => user.name.toLowerCase().includes(state.search.toLowerCase()) ||
          user.email.toLowerCase().includes(state.search.toLowerCase()))
      }
    
      return auxUsers
    }
  }
})
