<template>
  <div>
    <v-dialog
        v-model="updateCategoryModel"
        persistent
        max-width="600px"
    >
        <v-card>
        <v-container>
            <v-row no-gutters>
            <v-text-field
                required
                v-model="categoryName"
                variant="outlined"
                hide-details
                label="Nome"
            ></v-text-field>
            </v-row>
        </v-container>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
            color="error"
            variant="text"
            @click="updateCategoryModel = false"
            prepend-icon="mdi-cancel"
            >
            Cancelar
            </v-btn>
            <v-btn
            color="teal"
            variant="text"
            @click="updateCategory()"
            prepend-icon="mdi-pencil-outline"
            >
            Editar
            </v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import axios from 'axios'
import { useSnackbarStore } from '@/stores/snackbarStore'

const snackbarStore = useSnackbarStore()

const api = import.meta.env.VITE_API

const updateCategoryModel = defineModel()
const props = defineProps(['currentUser', 'category'])
const emit = defineEmits(['refresh'])

const currentUser = ref(props.currentUser)
const category = ref(props.category)
const categoryName = ref('')
const categoryIcon = ref('')
const categorybranch = ref(false)
const categorySubbranch = ref('')

watch(props, () => {
  currentUser.value = props.currentUser
  category.value = props.category
  categoryName.value = category.value.name
  categoryIcon.value = category.value.icon
  categorybranch.value = category.value.branch
  categorySubbranch.value = category.value.subbranch
})

const updateCategory = async () => {
  const updatedCategory = {
    _id: category.value._id,
    name: categoryName.value,
    icon: categoryIcon.value,
    branch: categorybranch.value,
    subbranch: categorySubbranch.value
  }

  await axios.get(api + '/status', { timeout: 2000 }).then(async () => {
    await axios.post(api + '/manager/category', { author: currentUser.value.email, category: updatedCategory }, { headers: { Authorization: 'Bearer ' + currentUser.value.token } })
      .then(async response => {
        categoryName.value = response.data.category.name
        refresh()
        await axios.post(api + '/manager/revision', { userEmail: currentUser.value.email }, { headers: { Authorization: 'Bearer ' + currentUser.value.token } })
          .catch(error => {
            snackbarStore.openSnackbar('Não foi possível salvar as alterações no histórico')
            console.log(error)
          })
      })
      .catch(error => {
        snackbarStore.openSnackbar('Não foi possível atualizar a categoria')
        console.log(error)
      })
  }).catch(error => {
    snackbarStore.openSnackbar('Não foi possível atualizar a categoria')
    console.log('Error fetching status:', error)
  })
}

const refresh = () => {
  updateCategoryModel.value = false
  emit('refresh', categoryName.value)
  category.value = ''
  categoryName.value = ''
}

</script>
