<template>
  <v-row>
    <v-col xs="12" sm="12" md="10" offset-md="1" lg="8" offset-lg="2" xl="6" offset-xl="3" class="my-6">
      <v-card
        title="Oops"
        subtitle="Nenhuma atividade encontrada!"
        :elevation="6" class="px-3 py-2"
        prepend-icon="mdi-alert-circle">
      </v-card>
    </v-col>
    <v-col class="ma-15" align="center">
      <v-img src="@/assets/embrapa.png" style="width: 150px;"></v-img>
    </v-col>
  </v-row>
</template>
