<template>
    <div>
        <v-navigation-drawer
        v-model="drawer"
        :permanent="mdAndUp"
        :width="xs ? 200 : 300"
        >
        <v-list density="comfortable">
          <v-list-item
          prepend-icon="mdi-account-group"
          value="users"
          :class="`${lastClicked === 'users' ? 'active' : ''}`"
          @click="drawerOptions('users')"
          >
          <v-list-item-title class="text-wrap" style="font-size: 18px;">Usuários</v-list-item-title>
          <template v-slot:append v-if="mdAndUp">
            <v-icon>{{ openUsersList ? 'mdi-chevron-left' : 'mdi-chevron-right' }}</v-icon>
          </template>
          </v-list-item>
          <v-list-item
          prepend-icon="mdi-sprout"
          value="vegetal"
          :class="`${lastClicked === 'vegetal' ? 'active' : ''}`"
          @click="drawerOptions('vegetal')"
          >
          <v-list-item-title class="text-wrap" style="font-size: 18px;">Vegetal</v-list-item-title>
          <template v-slot:append v-if="mdAndUp">
            <v-icon>{{ openVegetalList ? 'mdi-chevron-left' : 'mdi-chevron-right' }}</v-icon>
          </template>
          </v-list-item>
          <v-list-item
          prepend-icon="mdi-cow"
          value="animal"
          :class="`${lastClicked === 'animal' ? 'active' : ''}`"
          @click="drawerOptions('animal')"
          >
          <v-list-item-title class="text-wrap" style="font-size: 18px;">Animal</v-list-item-title>
          <template v-slot:append v-if="mdAndUp">
            <v-icon>{{ openAnimalList ? 'mdi-chevron-left' : 'mdi-chevron-right' }}</v-icon>
          </template>
          </v-list-item>
        </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue'
import { useDisplay } from 'vuetify'

const { xs, mdAndUp } = useDisplay()

const drawer = defineModel()
const props = defineProps(['home', 'openUsersList', 'openVegetalList', 'openAnimalList', 'lastClicked'])
const emit = defineEmits(['refresh', 'listVegetalCategories', 'listAnimalCategories'])

const openUsersList = ref(props.openUsersList)
const openVegetalList = ref(props.openVegetalList)
const openAnimalList = ref(props.openAnimalList)

const activitiesListModel = ref(false)

const lastClicked = ref(props.lastClicked)

onMounted(() => {
  if (mdAndUp.value) drawer.value = true
  else drawer.value = false
})

watch(mdAndUp, () => {
  if (mdAndUp.value) drawer.value = true
  else drawer.value = false
})

watch(drawer, () => {
  if (drawer.value) {
    if (openUsersList.value) openUsersList.value = false
    if (openVegetalList.value) openVegetalList.value = false
    if (openAnimalList.value) openAnimalList.value = false
  }
})

watch(props, () => {
  openUsersList.value = props.openUsersList
  openVegetalList.value = props.openVegetalList
  openAnimalList.value = props.openAnimalList
  lastClicked.value = props.lastClicked
})

watch(props, () => {
  if (props.home) home()
})

const home = () => {
  openUsersList.value = false
  openVegetalList.value = false
  openAnimalList.value = false
  activitiesListModel.value = false
}

const drawerOptions = (option) => {
  switch (option) {
    case 'users':
      openUsersList.value = !openUsersList.value
      emit('refresh', openUsersList.value, openVegetalList.value, openAnimalList.value)
      break
    case 'vegetal':
      openVegetalList.value = !openVegetalList.value
      if (openVegetalList.value) emit('listVegetalCategories')
      emit('refresh', openUsersList.value, openVegetalList.value, openAnimalList.value)
      break
    case 'animal':
      openAnimalList.value = !openAnimalList.value
      if (openAnimalList.value) emit('listAnimalCategories')
      emit('refresh', openUsersList.value, openVegetalList.value, openAnimalList.value)
      break
    default:
      break
  }
}

</script>

<style scoped>

.active{
  border-left: 4px solid teal
}

</style>
