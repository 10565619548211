import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import vuetify from './plugins/vuetify'
// import storage from 'vue-localstorage'
// import VueLodash from 'vue-lodash'
// import lodash from 'lodash'

import VueMatomo from 'vue-matomo'
import { router, matomoConfig } from './router'

console.log('calendario-de-manejos / manager @ ' + import.meta.env.VITE_STAGE)
console.log('Version: ' + import.meta.env.VITE_VERSION)

Sentry.init({
  Vue: createApp(App),
  dsn: import.meta.env.VITE_SENTRY_DSN,
  release: import.meta.env.VITE_VERSION.split('-')[0],
  environment: import.meta.env.VITE_STAGE,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', window.location.hostname, /^\//]
    })
  ],
  tracesSampleRate: 1.0
})

// Create Vue app instance
const app = createApp(App)
// app.use(VueLodash, { name: 'custom', lodash: lodash })

// Registering the storage plugin
// app.use(storage)
// app.use(storage, {
//   name: 'ls',
//   bind: true
// })

app.use(createPinia())
app.use(vuetify) // Registering Vuetify plugin
app.use(router) // Registering Vue Router plugin
app.use(VueMatomo, matomoConfig) // Registering VueMatomo plugin

app.mount('#app')
