<template>
  <v-dialog v-model="wait" persistent max-width="300px">
    <v-card class="pa-5">
      <v-card-text style="text-align: center;">
        <v-progress-circular :size="size" indeterminate :color="color" />
      </v-card-text>
      <v-card-title class="headline" block style="text-align: center;">
        {{ message }}
      </v-card-title>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, watch } from 'vue'

const props = defineProps(['message', 'color', 'size'])
const message = ref(props.message)
const color = ref(props.color)
const size = ref(props.size)
const wait = ref(false)

// const openProgress = () => {
//   wait.value = true
// }

// const closeProgress = () => {
//   wait.value = false
// }

watch(props, () => {
  message.value = props.message
  color.value = props.color
  size.value = props.size
})
</script>
