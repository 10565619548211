import { defineStore } from 'pinia';

export const useSnackbarStore = defineStore('snackbar', {
  state: () => ({
    snackText: '',
    showSnack: false,
  }),
  actions: {
    openSnackbar(message) {
      this.snackText = message;
      this.showSnack = true;

      setTimeout(() => {
        this.showSnack = false;
        this.snackText = '';
      }, 3000);
    },
  },
});
