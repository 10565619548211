<template>
  <v-text-field
  v-model="search"
  density="compact"
  label="Pesquisar"
  prepend-inner-icon="mdi-magnify"
  variant="solo-filled"
  type="text"
  clearable
  flat
  hide-details
  single-line
  >
  </v-text-field>
</template>

<script setup>
import { ref, watch } from 'vue'

const emit = defineEmits(['search'])

const search = ref('')

watch(search, () => {
  if (search.value !== null) emit('search', search.value)
  else emit('search', '')
})
</script>
