<template>
  <div>
    <v-dialog
    v-model="removeUserModel"
    max-width="600px"
  >
    <v-card>
      <v-card-title>Apagar Usuário</v-card-title>
      <v-card-text class="mb-0 pb-0 text-justify">
      <p>
        Você pode <strong>apagar completamente o perfil de {{ currentUser.email }}</strong> a qualquer momento.
        Isto removerá todas as suas informações dos nossos servidores remotos, tal como dados pessoais, e outras informações importantes.
        Esteja ciente, no entanto, que esta é uma <u>ação irreversível</u>, ou seja, seus dados e histórico de acesso serão <u>permanentemente apagados</u>
        sem qualquer possibilidade de recuperação.
      </p>
    </v-card-text>
    <v-window v-model="step">
      <v-window-item>
        <v-card-actions>
          <v-switch
            color="primary"
            v-model="aware"
            label="Estou ciente e quero continuar."
            inset
            class="ml-2"
          />

          <v-spacer />

          <v-btn
            color="primary"
            text
            large
            @click="sendRemovePin()"
            :disabled="!aware"
            :loading="loading"
            append-icon="mdi-arrow-right"
          >
            Prosseguir
          </v-btn>
        </v-card-actions>
      </v-window-item>
      <v-window-item>
        <v-card-text class="text-justify red darken-2 white--text">
          Um número de 6 dígitos foi enviado para o e-mail <strong>{{ currentUser.email }}</strong>. Se tiver realmente certeza de que quer continuar, insira-o abaixo:
        </v-card-text>
        <div class="input-wrapper my-5" style="width: 280px; margin: 0 auto;">
          <v-otp-input v-model="pin" :length="6" />
        </div>
        <v-card-actions>
          <v-btn
            color="error"
            variant="text"
            @click="cancel()"
          >
            Cancelar
          </v-btn>

          <v-spacer />

          <v-btn
            color="warning"
            variant="flat"
            :disabled="pin.length !== 6"
            large
            @click="removeAccount()"
            :loading="loading"
            append-icon="mdi-check"
          >
            Apagar Usuário
          </v-btn>
        </v-card-actions>
      </v-window-item>
    </v-window>
    </v-card>
  </v-dialog>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import axios from 'axios'
import { errorMessage } from '@/helpers/error'
import { useUserStore } from '@/stores/userStore'
import { useSnackbarStore } from '@/stores/snackbarStore'
import { storeToRefs } from 'pinia'

const snackbarStore = useSnackbarStore()

const userStore = useUserStore()
const { currentUser } = storeToRefs(userStore)

const api = import.meta.env.VITE_API

const removeUserModel = defineModel()
const props = defineProps(['userId'])

const aware = ref(false)
const step = ref(0)
const error = ref(false)
const erroMessage = ref('')
const pin = ref('')
const loading = ref(false)

const handleError = (err) => {
  loading.value = false
  erroMessage.value = errorMessage(err)
  error.value = true
}

const checkOnline = () => {
  if (!navigator.onLine) {
    erroMessage.value = 'É necessário uma conexão com a internet para prosseguir! Por favor, verifique suas configurações de rede ou tente novamente mais tarde.'
    error.value = true
    return false
  }
  return true
}

const sendRemovePin = async () => {
  if (!checkOnline()) return

  loading.value = true

  if (currentUser.value.admin) {
    await axios.get(api + '/status', { timeout: 2000 }).then(async () => {
      await axios.post(api + '/manager/admin/removeUser/pin', { adminEmail: currentUser.value.email }, { headers: { Authorization: `Bearer ${currentUser.value.token}` } })
        .then(() => {
          loading.value = false
          step.value++
        })
        .catch(err => {
          snackbarStore.openSnackbar('Não foi possível enviar o pin de remoção')
          handleError(err)
        })
    }).catch(err => {
      snackbarStore.openSnackbar('Não foi possível enviar o pin de remoção')
      handleError(err)
    })
  } else {
    console.log('Acesso negado')
  }
}

const removeAccount = async () => {
  if (!checkOnline()) return

  loading.value = true

  if (currentUser.value.admin) {
    await axios.get(api + '/status', { timeout: 2000 }).then(async () => {
      await axios.post(`${api}/manager/admin/remove/${props.userId}`, { pin: pin.value }, { headers: { Authorization: `Bearer ${currentUser.value.token}` } })
        .then(async () => {
          refresh()
          await axios.post(api + '/revision', { userEmail: currentUser.value.email }, { headers: { Authorization: 'Bearer ' + currentUser.value.token } })
            .catch(error => {
              snackbarStore.openSnackbar('Não foi possível salvar as alterações no histórico')
              console.log(error)
            })
        })
        .catch(err => {
          snackbarStore.openSnackbar('Não foi possível remover o usuário')
          handleError(err)
        })
    }).catch(err => {
      snackbarStore.openSnackbar('Não foi possível remover o usuário')
      handleError(err)
    })
  } else {
    console.log('Acesso negado')
  }
}

const cancel = () => {
  removeUserModel.value = false
}

const refresh = () => {
  removeUserModel.value = false
  snackbarStore.openSnackbar('Usuário apagado com sucesso!')
  loading.value = false
  pin.value = ''
  aware.value = false
  step.value--
  userStore.fetchUsers()
}

</script>
