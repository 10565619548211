import { defineStore } from 'pinia'
import { ref } from 'vue'
import axios from 'axios'

const api = import.meta.env.VITE_API

const currentUser = ref({})
const storedUser = JSON.parse(localStorage.getItem('user'))

if (storedUser?.authenticated) {
  currentUser.value = storedUser
}

export const useChangesStore = defineStore('changes', {
  state: () => ({
    changes: [],
    revisions: [],
    search: ""
  }),

  actions: {
    async listRevisions() {
      await axios.get(api + '/status', { timeout: 2000 }).then(async () => {
        await axios.get(api + '/revision/list', { headers: { Authorization: 'Bearer ' + currentUser.value.token } })
          .then(response => {
            this.revisions = response.data
          })
          .catch(error => {
            console.log(error)
          })
      }).catch(error => {
        console.log('Error fetching status:', error)
      })
    },
    async deleteLog() {
      await axios.get(api + '/status', { timeout: 2000 }).then(async () => {
        await axios.delete(api + '/manager/revision/all', { headers: { Authorization: 'Bearer ' + currentUser.value.token } })
          .then(response => {
            console.log(response.data)
            this.listRevisions()
          })
          .catch(error => {
            console.log(error)
          })
      }).catch(error => {
        console.log('Error fetching status:', error)
      })
    },
    sortUser(sortOrder) {
      if (!Array.isArray(this.filteredRevisions)) {
        console.error('filteredRevisions is not an array.')
        return
      }
    
      this.filteredRevisions.sort((a, b) => {
        let aValue = ''
        let bValue = ''
    
        aValue = a && a.user.email !== undefined && a.user.email !== null ? a.user.email.toString() : ''
        bValue = b && b.user.email !== undefined && b.user.email !== null ? b.user.email.toString() : ''
    
        // Compare values based on the specified order
        if (sortOrder === 'asc') {
          return aValue.localeCompare(bValue)
        } else {
          return bValue.localeCompare(aValue)
        }
      })
    
      // console.log('Sorting by: email', sortOrder)
      // this.filteredRevisions.forEach(revision => {
      //   if (revision.user.email) {
      //     console.log(revision.user.email)
      //   } else {
      //     console.warn('Missing email for revision.user:', revision.user)
      //   }
      // })
    },
    sortDate(sortOrder) {
      if (!Array.isArray(this.filteredRevisions)) {
        console.error('filteredRevisions is not an array.')
        return
      }
    
      this.filteredRevisions.sort((a, b) => {
        let aValue = ''
        let bValue = ''
    
        aValue = a && formatDate(a.date) !== undefined && formatDate(a.date) !== null ? formatDate(a.date).toString() : ''
        bValue = b && formatDate(b.date) !== undefined && formatDate(b.date) !== null ? formatDate(b.date).toString() : ''
    
        // Compare values based on the specified order
        if (sortOrder === 'asc') {
          return aValue.localeCompare(bValue)
        } else {
          return bValue.localeCompare(aValue)
        }
      })
    
      // console.log('Sorting by: email', sortOrder)
      // this.filteredRevisions.forEach(revision => {
      //   if (revision.date) {
      //     console.log(formatDate(revision.date))
      //   } else {
      //     console.warn('Missing date for revision:', formatDate(revision.date))
      //   }
      // })
    }
  },

  getters: {
    filteredRevisions(state) {
      let auxRevisions = state.revisions
    
      if (state.search !== '') {
        auxRevisions = auxRevisions
          .filter(revision => revision.user.name.toLowerCase().includes(state.search.toLowerCase()) ||
          revision.user.email.toLowerCase().includes(state.search.toLowerCase()) ||
          formatDate(revision.date).toLowerCase().includes(state.search.toLowerCase()))
      }
    
      return auxRevisions
    }
  }
});
