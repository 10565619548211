<template>
  <v-container fluid>
    <v-row>
    <v-col xs="12" sm="12" md="10" offset-md="1" lg="8" offset-lg="2" xl="6" offset-xl="3" class="my-6">
      <v-card :elevation="6" class="px-3 py-2">
        <v-card-title>
          <div class="headline">
            Bem vindo(a)!
          </div>
        </v-card-title>
        <v-card-text class="body-1">
          <p>
            Este aplicativo do projeto <strong>Calendário de Manejos</strong> permite a ?...
          </p>
          <p>
            Objetiva apoiar produtores à...
          </p>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
  <v-row no-gutters>
    <v-spacer></v-spacer>
    <v-col class="ma-15" align="center">
      <v-img src="@/assets/embrapa.png" style="width: 150px;"></v-img>
    </v-col>
    <v-spacer></v-spacer>
  </v-row>
  </v-container>
</template>
