<template>
  <div>
    <v-dialog
    v-model="removeCategoryModel"
    max-width="600px"
  >
    <v-card>
      <v-card-title>Apagar Categoria</v-card-title>
      <v-card-text class="mb-0 pb-0 text-justify">
      <p>
        Você pode <strong>apagar completamente a categoria {{ categoryName }}</strong> a qualquer momento.
        Isto removerá todas as suas informações dos nossos servidores remotos.
        Esteja ciente, no entanto, que esta é uma <u>ação irreversível</u>, ou seja, os dados da categoria serão <u>permanentemente apagados</u>
        sem qualquer possibilidade de recuperação.
      </p>
    </v-card-text>
    <v-card-actions v-if="step === 0">
      <v-switch
        color="teal"
        v-model="aware"
        label="Estou ciente e quero continuar."
        inset
        class="ml-2"
      />

      <v-spacer />

      <v-btn
        color="teal"
        text
        large
        @click="step++"
        :disabled="!aware"
        :loading="loading"
        append-icon="mdi-arrow-right"
      >
        Prosseguir
      </v-btn>
    </v-card-actions>
    <v-card-actions v-if="step === 1">
      <v-btn
        color="error"
        variant="text"
        @click="cancel()"
      >
        Cancelar
      </v-btn>

      <v-spacer />

      <v-btn
        color="warning"
        variant="flat"
        large
        @click="removeCategory()"
        :loading="loading"
        append-icon="mdi-check"
      >
        Apagar Categoria
      </v-btn>
    </v-card-actions>
    </v-card>
  </v-dialog>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import axios from 'axios'
import { errorMessage } from '@/helpers/error'
import { useSnackbarStore } from '@/stores/snackbarStore'

const snackbarStore = useSnackbarStore()

const api = import.meta.env.VITE_API

const removeCategoryModel = defineModel()
const props = defineProps(['currentUser', 'categoryId'])
const emit = defineEmits(['openEmptyAlert'])

const aware = ref(false)
const step = ref(0)

const currentUser = ref(props.currentUser)
const error = ref(false)
const erroMessage = ref('')
const loading = ref(false)

const categoryId = ref(props.categoryId)
const categoryName = ref('')

watch(props, () => {
  currentUser.value = props.currentUser
  categoryId.value = props.categoryId
  getCategoryById()
})

const getCategoryById = async () => {
  await axios.get(api + '/status', { timeout: 2000 }).then(async () => {
    await axios.get(api + '/category', {
      params: { categoryId: categoryId.value },
      headers: { Authorization: 'Bearer ' + currentUser.value.token }
    })
      .then(response => {
        categoryName.value = response.data.name
      })
      .catch(error => {
        console.log(error)
      })
  }).catch(error => {
    console.log('Error fetching status:', error)
  })
}

const handleError = (err) => {
  loading.value = false
  erroMessage.value = errorMessage(err)
  error.value = true
}

const checkOnline = () => {
  if (!navigator.onLine) {
    erroMessage.value = 'É necessário uma conexão com a internet para prosseguir! Por favor, verifique suas configurações de rede ou tente novamente mais tarde.'
    error.value = true
    return false
  }
  return true
}

const removeCategory = async () => {
  if (!checkOnline()) return

  loading.value = true

  if (currentUser.value.admin) {
    await axios.get(api + '/status', { timeout: 2000 }).then(async () => {
      await axios.post(api + '/manager/category/remove', { adminEmail: currentUser.value.email, categoryId: categoryId.value }, { headers: { Authorization: `Bearer ${currentUser.value.token}` } })
        .then(async () => {
          refresh()
          await axios.post(api + '/manager/revision', { userEmail: currentUser.value.email }, { headers: { Authorization: 'Bearer ' + currentUser.value.token } })
            .catch(error => {
              snackbarStore.openSnackbar('Não foi possível salvar as alterações no histórico')
              console.log(error)
            })
        })
        .catch(err => {
          snackbarStore.openSnackbar('Não foi possível remover a atividade')
          handleError(err)
        })
    }).catch(err => {
      snackbarStore.openSnackbar('Não foi possível remover a atividade')
      handleError(err)
    })
  } else {
    console.log('Acesso negado')
  }
}

const cancel = () => {
  removeCategoryModel.value = false
  aware.value = false
  step.value--
}

const refresh = () => {
  removeCategoryModel.value = false
  snackbarStore.openSnackbar('Categoria apagada com sucesso!')
  loading.value = false
  aware.value = false
  step.value--
  emit('openEmptyAlert')
}

</script>
