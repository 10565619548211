<template>
  <div>
    <v-dialog
        v-model="updateActivityModel"
        persistent
        max-width="600px"
    >
        <v-card>
        <v-container>
            <v-row no-gutters class="mb-5">
              <v-select
                  required
                  v-model="activityCategoryName"
                  :items="categoriesList"
                  variant="outlined"
                  hide-details
                  label="Categoria"
              ></v-select>
            </v-row>
            <v-row no-gutters class="mb-5">
            <v-text-field
                required
                v-model="activityName"
                variant="outlined"
                hide-details
                label="Nome"
            ></v-text-field>
            </v-row>
            <v-row no-gutters>
              <v-text-field
                required
                v-model="activityDescription"
                variant="outlined"
                hide-details
                label="Descrição"
            ></v-text-field>
            </v-row>
        </v-container>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
            color="error"
            variant="text"
            @click="updateActivityModel = false"
            prepend-icon="mdi-cancel"
            >
            Cancelar
            </v-btn>
            <v-btn
            color="teal"
            variant="text"
            @click="updateActivity()"
            prepend-icon="mdi-pencil-outline"
            >
            Editar
            </v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import axios from 'axios'
import { useCategoriesAndActivitiesStore } from '@/stores/categoriesAndActivitiesStore'
import { useSnackbarStore } from '@/stores/snackbarStore'
import { storeToRefs } from 'pinia'

const snackbarStore = useSnackbarStore()

const categoriesAndActivitiesStore = useCategoriesAndActivitiesStore()

const api = import.meta.env.VITE_API

const updateActivityModel = defineModel()
const props = defineProps(['currentUser', 'activityId'])
const emit = defineEmits(['refresh'])

const { categoriesList } = storeToRefs(categoriesAndActivitiesStore)

const currentUser = ref(props.currentUser)
const activityId = ref(props.activityId)
const activityName = ref('')
const activityDescription = ref('')
const activityRecommended = ref(false)
const activityCategoryId = ref('')
const activityCategoryName = ref('')

watch(props, () => {
  currentUser.value = props.currentUser
  activityId.value = props.activityId
  getActivityById()
})

const getActivityById = async () => {
  await axios.get(api + '/status', { timeout: 2000 }).then(async () => {
    await axios.get(api + '/activity', {
      params: { activityId: activityId.value },
      headers: { Authorization: 'Bearer ' + currentUser.value.token }
    })
      .then(response => {
        activityName.value = response.data.name
        activityDescription.value = response.data.description
        activityRecommended.value = response.data.recommended
        activityCategoryName.value = response.data.category.name
      })
      .catch(error => {
        console.log(error)
      })
  }).catch(error => {
    console.log('Error fetching status:', error)
  })
}

const getCategoryIdByName = async () => {
  await axios.get(api + '/status', { timeout: 2000 }).then(async () => {
    await axios.get(api + '/category', {
      params: { categoryName: activityCategoryName.value },
      headers: { Authorization: 'Bearer ' + currentUser.value.token }
    })
      .then(response => {
        activityCategoryId.value = response.data._id
      })
      .catch(error => {
        console.log(error)
      })
  }).catch(error => {
    console.log('Error fetching status:', error)
  })
}

const updateActivity = async () => {
  await getCategoryIdByName()

  const updatedActivity = {
    _id: activityId.value,
    name: activityName.value,
    description: activityDescription.value,
    recommended: activityRecommended.value,
    categoryId: activityCategoryId.value
  }

  await axios.get(api + '/status', { timeout: 2000 }).then(async () => {
    await axios.post(api + '/manager/activity', { author: currentUser.value.email, activity: updatedActivity }, { headers: { Authorization: 'Bearer ' + currentUser.value.token } })
      .then(async () => {
        refresh()
        await axios.post(api + '/manager/revision', { userEmail: currentUser.value.email }, { headers: { Authorization: 'Bearer ' + currentUser.value.token } })
          .catch(error => {
            snackbarStore.openSnackbar('Não foi possível salvar as alterações no histórico')
            console.log(error)
          })
      })
      .catch(error => {
        snackbarStore.openSnackbar('Não foi possível atualizar a atividade')
        console.log(error)
      })
  }).catch(error => {
    snackbarStore.openSnackbar('Não foi possível atualizar a atividade')
    console.log('Error fetching status:', error)
  })
}

const refresh = () => {
  updateActivityModel.value = false
  activityId.value = ''
  activityName.value = ''
  activityDescription.value = ''
  activityRecommended.value = false
  activityCategoryId.value = ''
  emit('refresh')
}

</script>
