<template>
    <div>
        <v-navigation-drawer
        v-model="openAnimalList"
        temporary
        :width="300"
    >
        <v-list>
        <v-list-item class="pl-1 text-h6">
            <v-btn
            v-if="currentUser.admin"
            color="teal"
            variant="text"
            @click="newCategoryModel = true"
            icon="mdi-plus"
            class="pa-0"
            ></v-btn>
            Categorias
            <v-select
            v-model="selectedYear"
            label="Ano"
            :items="availableYears"
          ></v-select>
        </v-list-item>
        <v-list-item
            v-for="(category, index) in categoriesAndActivitiesStore.getAnimalCategories"
            :key="index"
            :prepend-icon="category.icon"
            @click="updateCategory(category.name)"
        >
            <v-list-item-title class="text-wrap">
            {{ category.name }}
            </v-list-item-title>
        </v-list-item>
        </v-list>
    </v-navigation-drawer>
    <!-- CREATE NEW CATEGORY -->
    <CreateCategoryDialog v-model="newCategoryModel" branch="animal" @refresh="emit('listCategories')" />
    </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import CreateCategoryDialog from '@/components/CreateCategoryDialog.vue'
import { useCategoriesAndActivitiesStore } from '@/stores/categoriesAndActivitiesStore'
import { useUserStore } from '@/stores/userStore'
import { storeToRefs } from 'pinia'

const userStore = useUserStore()
const { currentUser } = storeToRefs(userStore)
const categoriesAndActivitiesStore = useCategoriesAndActivitiesStore()

const openAnimalList = defineModel()
const emit = defineEmits(['refresh', 'listCategories'])
const props = defineProps(['currentUser', 'years'])

const newCategoryModel = ref(false)
const availableYears = ref(props.years)
const selectedYear = ref(new Date().getFullYear().toString())

watch(props, () => {
  availableYears.value = props.years
})

const updateCategory = (newCategory) => {
  openAnimalList.value = false
  emit('refresh', newCategory, selectedYear.value)
}

</script>
