<template>
  <v-app theme="light">
    <nav>
      <v-app-bar color="teal" position="fixed">
        <v-btn icon="mdi-arrow-left" @click="$router.push({ path: '/' })"></v-btn>

        <v-toolbar-title v-text="routeName" />

        <v-btn
          v-if="stage === 'development'"
          color="error"
          variant="flat"
          prepend-icon="mdi-trash-can-outline"
          text="Apagar Histórico"
          class="mr-3"
          @click="changesStore.deleteLog()"
        ></v-btn>

        <!-- SEARCH BAR -->
        <SearchForm class="mr-3" @search="handleSearch"/>
      </v-app-bar>
    </nav>
    <v-main>
      <v-table class="table-container">
        <template v-slot:default>
          <thead class="table-header">
          <tr>
            <th class="px-4 py-3">
              #
            </th>
            <th class="px-4 py-3">
              Usuário
              <v-icon size="small" v-if="sortUserOrder" @click="changesStore.sortUser('asc'); sortUserOrder = !sortUserOrder">
                mdi-arrow-up
              </v-icon>
              <v-icon size="small" v-if="!sortUserOrder" @click="changesStore.sortUser('dsc'); sortUserOrder = !sortUserOrder">
                mdi-arrow-down
              </v-icon>
            </th>
            <th class="px-4 py-3">
              Data
              <v-icon size="small" v-if="sortDateOrder" @click="changesStore.sortDate('asc'); sortDateOrder = !sortDateOrder">
                mdi-arrow-up
              </v-icon>
              <v-icon size="small" v-if="!sortDateOrder" @click="changesStore.sortDate('dsc'); sortDateOrder = !sortDateOrder">
                mdi-arrow-down
              </v-icon>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="revision in changesStore.filteredRevisions" :key="revision._id" class="border-b">
            <td class="px-4 py-3">
              {{ revision.order }}
            </td>
            <td class="px-4 py-3 font-medium text-grey-900">
              <span v-if="revision.user.name !== ''">
                {{ revision.user.name }}
                <br>
                <span class="text-grey-500">{{ revision.user.email }}</span>
              </span>
              <span v-else>
                {{ revision.user.email }}
              </span>
            </td>
            <td class="px-4 py-3">
              {{ formatDate(revision.date) }}
            </td>
          </tr>
        </tbody>
        </template>
      </v-table>
    </v-main>
  </v-app>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import SearchForm from '@/components/SearchForm.vue'
import { useRoute } from 'vue-router'
import { format } from 'date-fns'
import { pt } from 'date-fns/locale'
import { useChangesStore } from '@/stores/changesStore'
import { storeToRefs } from 'pinia'

const changesStore = useChangesStore()
const { search } = storeToRefs(changesStore)

const route = useRoute()

const routeName = route.name

const stage = import.meta.env.VITE_STAGE

const sortUserOrder = ref(false)
const sortDateOrder = ref(false)

onMounted(() => {
  changesStore.listRevisions()
})

const handleSearch = (searchValue) => {
  search.value = searchValue
}

const formatDate = (date) => {
  return format(new Date(date), "eeee', dia' d 'de' MMMM 'de' yyyy 'às' HH:mm:ss", { locale: pt })
}

</script>

<style scoped>

.bg-white {
  background-color: #ffffff;
  position: relative;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
}

.table-container {
  width: 100%;
}

.table-header {
  font-size: 0.9rem;
  color: #4b5563;
  text-transform: uppercase;
  background-color: #f3f4f6;
}

.action-link {
  color: #4f46e5;
  text-decoration: none;
}

.action-link:hover {
  text-decoration: underline;
}
</style>
