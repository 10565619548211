<template>
  <v-dialog v-model="dialog" :max-width="options.width" @keydown.esc="cancel()">
    <v-toolbar dark :color="options.color" dense>
      <v-toolbar-title class="white--text">
        {{ title }}
      </v-toolbar-title>
    </v-toolbar>
    <v-card tile>
      <v-card-text class="px-4 py-2" v-show="!!message" v-html="message" style="text-align: justify;" />
      <v-divider v-show="!!message" />
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="cancel()" v-if="options.declineText">
          {{ options.declineText }}
        </v-btn>
        <v-btn :color="options.color" dark @click="agree()"  v-if="options.confirmText">
          {{ options.confirmText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, watch } from 'vue'

const emit = defineEmits(['consent', 'dissent'])
const props = defineProps(['newTitle', 'newMessage', 'newOptions'])

const dialog = defineModel()
const res = ref(null)
const rej = ref(null)
const message = ref(null)
const title = ref(null)
const options = ref({
  color: 'primary',
  width: 290,
  confirmText: 'Confirmar',
  declineText: 'Cancelar'
})

const open = () => {
  title.value = props.newTitle
  message.value = props.newMessage
  Object.assign(options.value, props.newOptions)

  return new Promise((resolve, reject) => {
    res.value = resolve
    rej.value = reject
  })
}

watch(props, () => {
  open()
})

const agree = () => {
  res.value(true)
  dialog.value = false
  emit('consent')
}

const cancel = () => {
  res.value(false)
  dialog.value = false
  emit('dissent')
}
</script>
